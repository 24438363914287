import React, { useState, useEffect, useMemo } from 'react';
import { motion } from 'framer-motion';
import CountUp from 'react-countup';

export const YourScore = ({ score = 0, maxScore = 1000 }) => {
  const totalElementHeight = 200;
  const [axisY, setAxisY] = useState(totalElementHeight);
  const height = useMemo(() => score / maxScore, [score, maxScore]);
  const transitiony = useMemo(() => totalElementHeight * (1 - height), [
    height,
  ]);

  useEffect(() => {
    setAxisY(transitiony);
  }, [transitiony]);

  return (
    <div className="relative mb-16 pr-24">
      <svg
        className="w-full"
        width="212"
        height="202"
        viewBox="0 0 212 202"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M108.52 167.464C106.963 166.556 105.037 166.556 103.48 167.464L46.4622 200.733C42.704 202.926 38.1322 199.605 39.0564 195.353L53.0778 130.845C53.4606 129.083 52.8656 127.252 51.5204 126.052L2.26 82.1051C-0.986868 79.2085 0.759373 73.8341 5.08874 73.3991L70.7724 66.8001C72.5661 66.6199 74.1239 65.4881 74.8496 63.8379L101.423 3.4082C103.175 -0.574863 108.826 -0.574874 110.577 3.40819L137.15 63.8379C137.876 65.4881 139.434 66.6199 141.228 66.8001L206.911 73.3991C211.241 73.8341 212.987 79.2085 209.74 82.1051L160.48 126.052C159.134 127.252 158.539 129.083 158.922 130.845L172.944 195.353C173.868 199.605 169.296 202.926 165.538 200.733L108.52 167.464Z"
          fill="#E8E8E8"
        />
        <mask
          id="mask0"
          masktype="alpha"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="400"
          height="202"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M108.52 167.464C106.963 166.556 105.037 166.556 103.48 167.464L46.4622 200.733C42.704 202.926 38.1322 199.605 39.0564 195.353L53.0778 130.845C53.4606 129.083 52.8656 127.252 51.5204 126.052L2.26 82.1051C-0.986868 79.2085 0.759373 73.8341 5.08874 73.3991L70.7724 66.8001C72.5661 66.6199 74.1239 65.4881 74.8496 63.8379L101.423 3.4082C103.175 -0.574863 108.826 -0.574874 110.577 3.40819L137.15 63.8379C137.876 65.4881 139.434 66.6199 141.228 66.8001L206.911 73.3991C211.241 73.8341 212.987 79.2085 209.74 82.1051L160.48 126.052C159.134 127.252 158.539 129.083 158.922 130.845L172.944 195.353C173.868 199.605 169.296 202.926 165.538 200.733L108.52 167.464Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0)">
          <motion.path
            initial={{
              originX: 'center',
              originY: 'bottom',
              scaleY: 1,
              scaleX: 1.2,
              y: 200,
            }}
            animate={{ y: axisY }}
            transition={{ duration: 1.5 }}
            fill="url(#paint0_linear)"
            d="
            M 0 10
            C 100,0
              50,40
              200,0
            V 200 
            H 0
            V 0 
            Z"
          >
            <animate
              repeatCount="indefinite"
              fill="#454599"
              attributeName="d"
              dur="4s"
              values="
            M 0 10
            C 100,0
              50,40
              200,0
            V 200 
            H 0;

            M 0 20
            C 100,0
              100,30
              200,0
            V 200 
            H 0;

            M 0 10
            C 150,0
              150,40
              200,0
            V 200 
            H 0;

            M 0 10
            C 200,0
              200,40
              200,0
            V 200 
            H 0;

            M 0 10
            C 100,0
              100,40
              200,0
            V 200 
            H 0;

            M 0 20
            C 0,0
              50,30
              200,0
            V 200 
            H 0;

            M 0 10
            C 100,0
              50,40
              200,0
            V 200 
            H 0;
            "
            />
          </motion.path>
        </g>
        <defs>
          <filter
            id="filter0_d"
            x="5.76367"
            y="111.764"
            width="195.472"
            height="84.1604"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dy="2" />
            <feGaussianBlur stdDeviation="2" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow"
              result="shape"
            />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="9.8849"
            y1="113.764"
            x2="9.8849"
            y2="189.826"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#23D0FF" />
            <stop offset="1" stopColor="#54FFC0" />
          </linearGradient>
        </defs>
      </svg>
      <motion.div
        className="absolute right-0 grid grid-flow-col gap-x-2 self-center"
        initial={{ bottom: `calc(0% - 56px)` }}
        animate={{ bottom: `calc(${height * 100}% - 60px)` }}
        transition={{ duration: 1.5 }}
      >
        <svg
          className="self-center"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 10.8316L18.6569 0.674316L19.32 19.6627L0 10.8316Z"
            fill="black"
          />
        </svg>
        <div
          className="col-start-2 text-4xl font-light text-center"
          data-testid="your-score-value"
        >
          <CountUp end={score} />
        </div>
        <div className="col-start-2 text-xl font-light">BODŮ</div>
      </motion.div>
    </div>
  );
};
