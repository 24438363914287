import React from 'react';
import classNames from 'classnames';

export const ProgressBar = ({ className }) => {
  const css = classNames('flex flex-col w-6 space-y-1', className);

  return (
    <div className={css}>
      <div className="bg-grey-2 h-1 rounded-sm"></div>
      <div className="bg-grey-2 h-1 rounded-sm"></div>
      <div className="bg-grey-2 h-1 rounded-sm"></div>
      <div className="bg-grey-2 h-1 rounded-sm"></div>
      <div className="bg-green h-1 rounded-sm"></div>
      <div className="bg-green h-1 rounded-sm"></div>
      <div className="bg-green h-1 rounded-sm"></div>
    </div>
  );
};
