import React, { useContext, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { navigate } from '@reach/router';

import Clock from '../../../../../images/svg/clock.svg';
import userCheck from '../../../../../images/icons/user-check.svg';
import university from '../../../../../images/icons/university.svg';
import { Card } from '../../../../../components/shared/Card';
import { AuthContext } from '../../../../../contexts/AuthContext';
import { Button } from '../../../../shared/Button';
import { YourScore } from '../../../../shared/YourScore';
import { ScorePointsModal } from '../../ScorePointsModal';
import { useGTMEvent } from '../../../../../hooks/useGTMEvent';

export const ScoreCard = ({ score = 0, scorePercentile, scoreDate }) => {
  const { user } = useContext(AuthContext);
  const [showScorePointsModal, setShowScorePointsModal] = useState(false);
  const pushGTMEvent = useGTMEvent({
    event: 'dashboard:findoutscore',
    eventCategory: 'dashboard',
    eventAction: 'click',
    eventLabel: 'dashboard_findoutscore',
  });

  const isScored = !!score;

  const startOnboarding = () => {
    pushGTMEvent();
    navigate('/moje/skore/');
  };

  return (
    <Fragment>
      <ScorePointsModal
        showScorePointsModal={showScorePointsModal}
        setShowScorePointsModal={setShowScorePointsModal}
      />
      <Card
        header={
          <div className="flex justify-between items-center">
            <span data-testid="card-main-title">
              {isScored
                ? 'Kreditní skóre'
                : 'Pojďme zjistit Vaše Osobní skóre!'}
            </span>
            {scoreDate && (
              <div className="flex items-center text-gray text-base font-normal">
                <Clock className="inline-block mr-2 w-4 fill-grey-2" />
                {scoreDate}
              </div>
            )}
          </div>
        }
      >
        <div className="flex flex-wrap">
          <div className="flex flex-col flex-1">
            <div className="flex items-center mr-4">
              <YourScore score={score} />
            </div>
            <div>
              <button
                className="ml-1 underline"
                type="button"
                onClick={() => {
                  setShowScorePointsModal(true);
                }}
                data-testid="show-score-points-modal-button"
              >
                Více informací o bodování
              </button>
            </div>
          </div>
          <div className="flex flex-col sm:flex-1">
            {!!scorePercentile ? (
              <p className="text-lg">
                Máte {score} bodů. To je lepší výsledek než má {scorePercentile}{' '}
                % lidí v Česku.
              </p>
            ) : (
              <Fragment>
                <p
                  className="font-bold"
                  data-testid="card-main-instructions-title"
                >
                  Jste jen 2 kroky od cíle
                </p>
                <p
                  className="mt-6"
                  data-testid="card-main-instructions-subtitle"
                >
                  Pro vypočítání skóre budeme potřebovat:
                </p>
                <ul className="flex flex-col mt-2">
                  <li
                    className="flex items-center mt-2"
                    data-testid="card-main-instruction"
                  >
                    <img
                      className="h-4 mr-3"
                      src={userCheck}
                      alt="User"
                      aria-hidden="true"
                    />
                    <span>Několik informací o Vás</span>
                  </li>
                  <li
                    className="flex items-center mt-2"
                    data-testid="card-main-instruction"
                  >
                    <img
                      className="h-4 mr-3"
                      src={university}
                      alt="University"
                      aria-hidden="true"
                    />
                    <span>Bankovní výpisy za alespoň 3 měsíce</span>
                  </li>
                </ul>
              </Fragment>
            )}
            <Button
              type="button"
              onClick={startOnboarding}
              disabled={!user.allowNewScoring}
              className="my-6"
              data-testid="dashboard-start-onboarding"
            >
              Zjistit skóre
            </Button>
            <p
              className="mt-auto max-w-36ch"
              data-testid="card-main-instructions-note"
            >
              {isScored
                ? 'Osobní skóre si můžete nechat spočítat maximálně jednou za 14 dní.'
                : 'Bankovní výpisy po výpočtu skóre hned mažeme.'}
            </p>
          </div>
        </div>
      </Card>
    </Fragment>
  );
};

ScoreCard.propTypes = {
  score: PropTypes.number,
  scorePercentile: PropTypes.number,
  scoreDate: PropTypes.string,
};
