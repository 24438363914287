import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Angle from '../../../../../images/svg/angle.svg';
import Check from '../../../../../images/svg/check.svg';

export const AccordionItemHeader = ({ title, isCollapsed, isDone }) => {
  const disabled = isCollapsed && !isDone;

  const headingClasses = classNames('text-2xl font-bold flex items-center', {
    'border-b border-grey-1 pb-8 opacity-50': disabled,
  });

  const arrowClasses = classNames('w-5 h-3 ml-3', {
    'transform rotate-180 opacity-50': disabled,
  });

  return (
    <h2 className={headingClasses}>
      {title}
      {isDone ? (
        <Check className="w-6 h-6 ml-2 fill-green" />
      ) : (
        <Angle className={arrowClasses} />
      )}
    </h2>
  );
};

AccordionItemHeader.prototypes = {
  title: PropTypes.string,
  isCollapsed: PropTypes.bool,
};
