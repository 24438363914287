import React, { useContext } from 'react';
import { Formik, Form } from 'formik';
import { object } from 'yup';

import api from '../../../../utilities/api';
import { ErrorContext } from '../../../../contexts/ErrorContext';
import { AuthContext } from '../../../../contexts/AuthContext';
import { Page } from '../../../shared/Page';
import { FormGroup } from '../../../shared/form/FormGroup';
import { FormInput } from '../../../shared/form/FormInput';
import { FormGroupError } from '../../../shared/form/FormGroupError';
import { Button } from '../../../shared/Button';
import { useGTMEvent } from '../../../../hooks/useGTMEvent';

import { personalIdentificationNumber } from '../../../../validations';

const ValidationSchema = object().shape({
  personalNo: personalIdentificationNumber,
});

export const RegisterForm = () => {
  const { setUser } = useContext(AuthContext);
  const { setGlobalError } = useContext(ErrorContext);
  const pushGTMEvent = useGTMEvent({
    event: 'dashboard:insolvency:register:personalno',
    eventCategory: 'dashboard',
    eventAction: 'click',
    eventLabel: 'dashboard_insolvency_register_personalno',
  });

  const onSubmit = async values => {
    try {
      pushGTMEvent();
      const user = await api.updateUser(values);
      await api.loadInsolvence();
      setUser(user);
    } catch (error) {
      setGlobalError(error);
    }
  };

  return (
    <Page
      title="Insolvenční rejstřík"
      description="Po zadání rodného čísla budete moci ověřit své případné záznamy v insolvenčním rejstříku."
    >
      <Formik
        initialValues={{
          personalNo: '',
        }}
        validationSchema={ValidationSchema}
        onSubmit={onSubmit}
      >
        {({
          handleSubmit,
          handleChange,
          isSubmitting,
          isValid,
          dirty,
          errors,
          touched,
        }) => (
          <Form className="flex flex-col sm:max-w-sm mx-auto mt-6">
            <FormGroup>
              <FormInput
                type="text"
                title="Rodné číslo"
                name="personalNo"
                placeholder="Rodné číslo"
                errors={errors}
                touched={touched}
                onChange={handleChange}
                data-testid="insolvence-register-form-personal-no"
              />
              <FormGroupError name="personalNo" />
            </FormGroup>

            <Button
              type="submit"
              onClick={handleSubmit}
              border="normal"
              disabled={isSubmitting || !dirty || !isValid}
              className="mt-6"
              data-testid="insolvence-register-form-submit"
            >
              Vyhledat
            </Button>
          </Form>
        )}
      </Formik>

      <p
        className="text-center pt-12 text-xl max-w-3xl mx-auto"
        data-testid="insolvence-register-form-description"
      >
        Souhlasím s využitím rodného čísla pro provedení dotazu do insolvenčního
        rejstříku a&nbsp;evidenci provedených dotazů.
      </p>
    </Page>
  );
};
