import React from 'react';
import classNames from 'classnames';

export const Chart = ({ className }) => {
  const css = classNames('flex items-end space-x-1', className);

  return (
    <div className={css}>
      <div className="bg-grey-2 w-3 h-4"></div>
      <div className="bg-grey-2 w-3 h-8"></div>
      <div className="bg-green w-3 h-12"></div>
    </div>
  );
};
