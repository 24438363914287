import React from 'react';
import PropTypes from 'prop-types';
import { ProgressBarStep } from './ProgressBarStep';

import className from 'classnames';

export const ProgressBar = ({ steps, top }) => {
  const classes = className('h-full flex flex-col items-center', {
    'justify-center': !top,
    'pt-32': top,
  });

  return (
    <section className={classes}>
      {steps.map(step => (
        <ProgressBarStep key={step.key} {...step} />
      ))}
    </section>
  );
};

ProgressBar.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.object).isRequired,
};
