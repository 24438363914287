import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const AccordionItem = ({
  children,
  isCollapsed,
  className,
  headerComponent,
}) => {
  const classes = classNames('block', {
    hidden: isCollapsed,
    block: !isCollapsed,
  });

  return (
    <div className={className}>
      {React.cloneElement(headerComponent, { isCollapsed })}
      <div className={classes} aria-expanded={isCollapsed}>
        {React.cloneElement(children, { isCollapsed })}
      </div>
    </div>
  );
};

AccordionItem.propTypes = {
  children: PropTypes.node,
  headerComponent: PropTypes.node,
  className: PropTypes.string,
  isCollapsed: PropTypes.bool,
};

export const Accordion = ({ expandedItem, children }) => {
  const [bindIndex, setBindIndex] = useState(expandedItem);

  useEffect(() => {
    setBindIndex(expandedItem);
  }, [expandedItem]);

  const changeItem = itemIndex => {
    if (itemIndex !== bindIndex) {
      setBindIndex(itemIndex);
    }
  };

  return children.map(({ props: { index, ...rest } }) => (
    <AccordionItem
      key={index}
      isCollapsed={bindIndex !== index}
      handleClick={() => changeItem(index)}
      {...rest}
    />
  ));
};

Accordion.propTypes = {
  expandedItem: PropTypes.string,
  children: PropTypes.node,
};
