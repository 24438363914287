import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Oval from '../../../../images/svg/oval.svg';
import OvalActive from '../../../../images/svg/oval-active.svg';

export const ProgressBarStep = ({ state, label }) => {
  const classNameOval = classNames('w-6', {
    'fill-grey-3 opacity-25': state === 'pending',
    'fill-primary': state === 'done',
  });

  const classNameOvalActive = classNames('w-12', {
    'fill-primary': true,
  });

  const classNameLabelWrapper = classNames('flex flex-1', {
    'font-bold': state === 'in-progress',
  });

  return (
    <div className="flex flex-row justify-between items-center my-4 w-2/3">
      <div className="w-12 flex flex-shrink-0 justify-center items-center">
        {state !== 'in-progress' && <Oval className={classNameOval} />}
        {state === 'in-progress' && (
          <OvalActive className={classNameOvalActive} />
        )}
      </div>
      <div className={classNameLabelWrapper}>
        <span className="pl-8">{label}</span>
      </div>
    </div>
  );
};

ProgressBarStep.propTypes = {
  state: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};
