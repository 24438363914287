import React from 'react';
import classNames from 'classnames';

import loading from '../../../images/loading.svg';

export const Spinner = ({ size, className }) => {
  const css = classNames('animate-spin', className);

  return (
    <div className="flex" data-testid="spinner">
      <img
        alt="spinner"
        width={size}
        height={size}
        src={loading}
        className={css}
        data-testid="spinner-img"
      />
    </div>
  );
};
