import React, { useContext, useEffect, Suspense } from 'react';

import { AuthContext } from '../../../contexts/AuthContext';
import { Layout } from '../../../components/shared/Layout';
import { Seo } from '../../../components/shared/Seo';
import { Spinner } from '../../../components/shared/Spinner';
import { Insolvence } from './cards/Insolvence';
import { WelcomeModal } from './WelcomeModal';
import { useVocative } from '../../../hooks/useVocative';
import { useApplicationScore } from '../../../hooks/useApplicationScore';
import { ScoreHintCard } from './cards/ScoreHintCard';
import { ScoreCard } from './cards/ScoreCard';
import { ZonkyLoan } from './cards/ZonkyLoan';
// import { Distraint } from './cards/Distraint';

const DashboardContent = () => {
  const { user, loadAndSetUser } = useContext(AuthContext);
  const { firstName } = user;
  const { vocative } = useVocative(firstName);
  const {
    result,
    recommendations,
    scoringDateFinished,
  } = useApplicationScore();

  useEffect(() => {
    if (result) {
      loadAndSetUser();
    }
  }, [result, loadAndSetUser]);

  return (
    <Layout theme="in-app">
      <Seo title="Nástěnka" />
      <WelcomeModal />
      <section className="max-w-screen-xl mx-auto lg:flex flex-col">
        <div className="flex flex-col">
          <h1
            className="mb-4 text-4xl font-normal tracking-wide"
            data-testid="dashboard-title"
          >
            Dobrý den, <span className="font-bold">{vocative},</span>
          </h1>
          <p className="text-lg" data-testid="dashboard-subtitle">
            připravili jsme pro Vás několik ukazatelů Vaší kredibility.
          </p>
        </div>

        <div className="flex flex-col mt-12 lg:flex-row space-y-6 lg:space-y-0">
          <div className="flex flex-col lg:flex-grow space-y-6 lg:flex-shrink-0 lg:w-4/6 lg:mr-6">
            <ScoreCard
              score={result?.finalScore}
              scorePercentile={result?.finalScorePercentile}
              scoreDate={scoringDateFinished}
            />
            {/* <Distraint /> NOT SUPPORTED YET */}
            <Suspense fallback={<Spinner size={64} />}>
              <Insolvence />
            </Suspense>
          </div>

          <aside className="flex-col lg:flex-shrink lg:flex-grow space-y-6">
            <ScoreHintCard recommendations={recommendations} />

            {!!result && (
              <ZonkyLoan
                estimatedInterestRate={result.estimatedInterestRate}
                estimatedInterestRateOthers={result.estimatedInterestRateOthers}
              />
            )}
          </aside>
        </div>
      </section>
    </Layout>
  );
};

export const Dashboard = () => (
  <Suspense fallback={<Spinner size={64} className="mx-auto" />}>
    <DashboardContent />
  </Suspense>
);
