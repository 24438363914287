import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

export const Card = ({ children, disabled, theme, header }) => {
  const cardCss = classNames(
    'flex flex-col p-4 sm:p-8 bg-white rounded-md shadow-md',
    {
      'border border-grey-2 border-dashed bg-transparent shadow-none':
        'transparent' === theme,
    }
  );

  const headerCss = classNames('text-2xl font-bold', {
    'opacity-50': disabled,
  });

  const contentCss = classNames('mt-6', {
    'opacity-50': disabled,
  });

  return (
    <div className={cardCss} data-testid="card">
      <h2 className={headerCss} data-testid="card-header">
        {header}
      </h2>
      <div className={contentCss} data-testid="card-body">
        {children}
      </div>
    </div>
  );
};

Card.propTypes = {
  theme: PropTypes.string,
  disabled: PropTypes.bool,
  children: PropTypes.node,
  header: PropTypes.any,
};
