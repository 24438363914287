import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from '@reach/router';

import { AuthContext } from '../../contexts/AuthContext';

const AuthenticatedRoute = ({ component: Component, ...rest }) => {
  const { isLoading, user } = useContext(AuthContext);

  if (!isLoading && !user) {
    return <Redirect to="/ucet/prihlaseni" noThrow />;
  }

  if (!user) {
    return null;
  }

  return <Component {...rest} />;
};

AuthenticatedRoute.propTypes = {
  component: PropTypes.any.isRequired,
};

export default AuthenticatedRoute;
