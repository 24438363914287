import React from 'react';
import Check from '../../../images/svg/check.svg';

export const IconSelect = props => (
  <div
    className="flex flex-wrap justify-center mx-auto max-w-screen-sm"
    {...props.test}
  >
    {props.options &&
      props.options.map(option => (
        <label
          key={option.code}
          className="cursor-pointer flex flex-col items-center w-48 m-2"
          htmlFor={option.code}
          data-testid={`icon-select-label-${option.code}`}
        >
          {option.code === props.value && (
            <span className="absolute pointer-events-none block border border-secondary rounded-large w-48 h-48">
              <Check className="absolute w-6 h-6 top-0 right-0 mt-3 mr-3 fill-secondary" />
            </span>
          )}
          <input
            className="hidden"
            type="radio"
            name={props.name}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            id={option.code}
            value={option.code}
            checked={option.code === props.value}
          />
          <img
            className="w-32 h-32 pb-3 mt-6"
            src={props.iconsMap[option.code]}
            alt={option.name}
          />
          <p className="">{option.name}</p>
        </label>
      ))}
  </div>
);
